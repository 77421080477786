// this is the prompt service which facilitates communications
//  with the server to send prompt invites

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'app/shared/utils/Utils';
import { forkJoin, Observable } from 'rxjs';
import { Request, RequestAssignedPayload } from './request.model';

import { FulFillRequestPayload, CreateRequestPayload } from './request.model';
import { fork } from 'child_process';

interface Msg {
  msg: string;
  date: Date;
}

@Injectable()
export class RequestService {

  constructor(private http: HttpClient) {}

  public sendRequestFulfillEmail(payload: FulFillRequestPayload): Observable<Msg> {
    return this.http.post<Msg>('/api/email/fulfill-request', payload);
  }

  public sendRequestCreatedEmail(request: Request): Observable<Msg> {
    const vars = {
      id: request.id,
      requesterName: request.requesterName,
      requesterEmail: request.requesterEmail,
      number: request.number,
      needDescription: request.needDescription,
      needFor: request.needFor,
      needByDate: request.needByDate,
    }
    const payload: CreateRequestPayload = {
      request: vars,
      hostname: Utils.fullHost()

    };
    return this.http.post<Msg>('/api/email/create-request', payload);
  }

  public sendRequestAssignedEmail(request: Request): Observable<Msg[]> {
    const emailRequests$: Observable<Msg>[] = [];
    const payload: RequestAssignedPayload = {
      request: {
        id: request.id,
        requestStatus: request.requestStatus, // not used
        number: request.number,
        needDescription: request.needDescription,
        needFor: request.needFor,
        needByDate: request.needByDate,
        requesterName: request.requesterName,
        teamId: request.teamId
      },
      hostname: Utils.fullHost(),
    };
    if (request.team?.name) {
      payload.request.team = { 
        id: request.team.id, // not used
        name: request.team.name, 
        client: null // not used
      };
    }
    request.assignedTo_users?.forEach(assignedTo_user => {
      const user = assignedTo_user.assignedTo;
      const emailPayload = JSON.parse(JSON.stringify(payload));

      if (user?.email) {
        emailPayload.request.assignedTo = { email: user.email };
        emailRequests$.push(this.http.post<Msg>('/api/email/request-assigned', emailPayload));
      }
    });
    return forkJoin(emailRequests$);
  };

  public shareRequestEmail(payload: any): Observable<Msg> {
    return this.http.post<Msg>('/api/email/share-request-trusted-contact', payload);
  }

}
