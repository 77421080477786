import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Subscription } from 'apollo-angular';


import { DynamicFieldEntityModel } from '../dynamic-field.model'

// must be unique across all slapfive gql
export const LIST_ALL_DYNAMIC_FIELDS_QUERY_NAME = 'ListAllDynamicFieldsQuery';

export interface ListAllDynamicFieldsSubscriptionResult {
  DynamicFieldEntity: [DynamicFieldEntityModel];
}

@Injectable({
  providedIn: 'root'
})
export class ListAllDynamicFieldsService extends Subscription<ListAllDynamicFieldsSubscriptionResult>{

  document = gql`
    subscription ${LIST_ALL_DYNAMIC_FIELDS_QUERY_NAME} {
      DynamicFieldEntity {
        entityName: entity_name
        dynamicFields(order_by: [{ sort: asc}, {label: asc }]) {
          id
          client_id
          label
          description
          required
          type
          sort
          dataName: data_name
          entityName: entity_name
          dynamicFieldPickListValues(order_by: {name: asc}) {
            id
            name
            order
          }
        }
      }
    }

  `;
}
