import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DynamicFieldModel } from '../dynamic-field.model';
import cuid from 'cuid';

import { ListDynamicFieldPickValuesService } from '../dynamic-fields-gql/list-dynamic-field-pick-values.service';
import { SaveDynamicFieldValueService } from '../dynamic-fields-gql/save-dynamic-field-value.service';
import { DeleteDynamicFieldValueService } from '../dynamic-fields-gql/delete-dynamic-field-value.service';
import { DynamicFieldPickListValue } from '../dynamic-field.model';
import { FindMemberOrCompanyGqlService } from '../../members/members-gql/find-member-or-company-gql.service';
import { User } from '../../users/user.model';
import { CurrentUserService } from '../../../shared/current-user/current-user.service';
import { Utils } from '../../../shared/utils/Utils';

@Component({
  selector: 's5-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss']
})
export class DynamicFieldComponent implements OnInit {

  _dynamicField: DynamicFieldModel;
  get dynamicField() {return this._dynamicField;}
  @Input() set dynamicField(df) {
    if (df) {
      this._dynamicField = df;
      this.processDynamicField();
    }
  };
  @Input() entityId:string;
  @Input() showLabel = true;
  @Output() valueChanged = new EventEmitter<any>();

  value = [];
  startingValue = [];
  pickListValue$:Observable<DynamicFieldPickListValue[]>;
  allUsers:User[];

  constructor(
    private listDynamicFieldPickValuesService:ListDynamicFieldPickValuesService,
    private saveDynamicFieldValueService:SaveDynamicFieldValueService,
    private deleteDynamicFieldValueService:DeleteDynamicFieldValueService,
    private findMemberOrCompanyGqlService: FindMemberOrCompanyGqlService,
    private currentUserService: CurrentUserService,
  ) { }

  async ngOnInit() {
    const user = await this.currentUserService.getUser();
    this.allUsers = Utils.usersForUsersClient(user);
  }

  processDynamicField() {
    this.pickListValue$ = this.listDynamicFieldPickValuesService.subscribe({ dynamicFieldId: this.dynamicField?.id })
      .pipe(map(({ data }) => {
        return data.DynamicFieldPickListValue;
      })
    );
    this.startingValue = this.dynamicField.dynamicFieldDataMappings[0]?.dynamicFieldData || [];
    this.dynamicField.dynamicFieldDataMappings[0]?.dynamicFieldData.forEach(dfd => {
      // construct correct input types
      let id: String | Boolean | Number = dfd.value;
      if (this.dynamicField.type === 'YESNO') {
        id = dfd.value === 'true'
        this.value[0] = {id, data_id: dfd.id, name: dfd.pickListValue?.name};
      } else if (this.dynamicField.type === 'NUM') {
        id = Number(dfd.value);
        this.value[0] = {id, data_id: dfd.id, name: dfd.pickListValue?.name};
      } else if (['MEMBER', 'COMPANY', 'MEMBERCOMPANY', 'ACTIVITYTYPE'].indexOf(this.dynamicField.type) !== -1) {
        this.value[0] = dfd.company || dfd.customer || dfd.activityType;
        
        if (!dfd.user && !this.value[0]) {
          !dfd.user && !dfd.company && !dfd.customer && !dfd.activityType && this.findMemberOrCompanyGqlService.watch({ id: dfd.value }).valueChanges.subscribe(({ data, loading }) => {
            const value =  data.Customer || data.Company;
            if (value) {
              this.value[0] = value;
            }
          });
        }
      } else if (this.dynamicField.type === 'USER') {
        const user = dfd.user || this.allUsers.find(u => u.id === dfd.value);
        
        if (user) {
          this.value[0] = user;
        }
      } else {
        this.value.push({id, data_id: dfd.id, name: dfd.pickListValue?.name});
      }
    });

    if (this.dynamicField.type.indexOf('PICK') === -1 && this.value.length === 0) {
      this.value.push({id: ''});
    }
  }

  searchFn(str, item) {
    return item.name.toUpperCase().indexOf(str.toUpperCase()) !== -1;
  }

  save(entity?:any, mappings?:any) {
    // collect changes
    // if this is an existing mapping use it, otherwise we're creating a new one with a new id
    const dynamicFieldDataMappingId = mappings && mappings[0]?.id || this.dynamicField.dynamicFieldDataMappings[0]?.id || cuid();
    // check for cleared fields
    if (!this.value[0]) {
      this.value = [];
    }
    this.startingValue = mappings && mappings[0]?.dynamicFieldData || this.startingValue;
    // compare current vs initial field data to determine whether to add, update, or drop the data
    const changes: Observable<any>[] = [];
    this.value.map((v: any) => {
      const startingMatched = this.startingValue.find((sv: any) => sv.id && sv.id === v.data_id);

      // add/update when v.id has changed and is truthy
      if (!!v.id && (!startingMatched || v.id !== startingMatched.value)) {
        const vars = {
          dynamicFieldId: this.dynamicField.id,
          entityId: entity?.id || this.entityId,
          dynamicFieldDataId: v.data_id || cuid(),
          dynamicFieldDataMappingId,
          value: String(v.id)
        };
        changes.push(this.saveDynamicFieldValueService.mutate(vars));
      }
      // drop for other than pick lists when v.id is falsy
      else if (!v.id && startingMatched) {
        !!v['data_id'] && changes.push(this.deleteDynamicFieldValueService.mutate({ id: v['data_id'] }));
      }
    });
    // drop for pick lists or if there was an accumulation of dynamicFieldData elements on non pick lists
    this.startingValue.map((sv: any) => {
      const endingMatched = this.value.find((v: any) => v.data_id === sv.id);

      if (!endingMatched) {
        changes.push(this.deleteDynamicFieldValueService.mutate({ id: sv.id }));
      }
    });

    return changes;
  }

}
