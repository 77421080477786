import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { User } from 'app/+client-admin/users/user.model';
import gql from 'graphql-tag';


export const REQUEST_USERS_LIST_QUERY_NAME = 'UserListQuery';

export interface RequestUsersListQueryResults {
  current_user: [User];
}

@Injectable({
  providedIn: 'root'
})
export class RequestUsersGqlService extends Query<RequestUsersListQueryResults> {

  document = gql`
    query ${REQUEST_USERS_LIST_QUERY_NAME} (
      $limit: Int, $offset: Int, $searchVal: String, $clientId: String
    ) {
      User(
        limit: $limit
        offset: $offset
        order_by: {name: asc}
        where: {
          clientRoles: {ClientRole: {client_id: {_eq: $clientId}}},
          name: {_ilike: $searchVal}
        }
      ) {
        id
        email
        familyName
        givenName
        name
        phone
        avatarImage {
          id
          url
        }
        clientRoles {
          allowRequestAssignment
          notifyNewPromptResponse
          notifyNewRequests
          prefilterMembers
          ClientRole {
            role
            client {
              id
              name
            }
          }
        }
        currentRole {
            role
        }
      }
    }
  `;
}
